.advantages-block {
  padding: 140px 0;
  display: flex;
  @include _1650 {
    padding: 100px 0;
    @include _1440 {
      padding: 80px 0;
      @include _1200 {
        padding: 60px 0;
        @include _768 {
          padding: 45px;
          @include _576 {
            padding: 35px 0 45px;
          }
        }
      }
    }
  }
  position: relative;
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    z-index: 1;
    @include _1200 {
      align-items: center;
    }
  }

  &__wrapper {
    max-width: 700px;
  }
  &__title {
    @include font(32,46);
    @include _1650 {
      @include font(28,40);
      @include _1440 {
        @include font(26,36);
        @include _1200 {
          @include font(24,30);
          @include _992 {
            @include font(20,26);
            @include _576 {
              max-width: 206px;
              @include font(16,23);
            }
          }
        }
      }
    }
    color: $black;
    font-weight: 500;
    text-transform: uppercase;
    & > span {
      @include gradient-text($main-gradient);
    }
  }
  &__description {
    margin-top: 30px;
    @include font(18,22);
    @include _1650 {
      @include font(16,20);
      margin-top: 25px;
      @include _1440 {
        @include font(14,18);
        margin-top: 20px;
        @include _992 {
          @include font(12,14);
          margin-top: 10px;
          @include _576 {
            margin-top: 5px;
            @include font(10,12);
          }
        }
      }
    }
    font-weight: 400;
    color: $black;
  }
  &__title, &__description {
    @include _1200 {
      text-align: center;
      @include _576 {
        text-align: left;
      }
    }
  }
  &__container {
    margin-top: 60px;
    @include _1440 {
      margin-top: 40px;
      @include _992 {
        margin-top: 30px;
        @include _576 {
          margin-top: 20px;
        }
      }
    }
  }
  &-h {
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
  }
  &__img {
    max-width: scaleFluid(887, 0.88, 1650, 1920);
    @include _1200 {
      display: none;
    }
    &:after {
      content: "";
      padding-top: 100%;
    }
  }
  &-item {
    background: $black-gradient;
    padding: 25px 30px;
    display: flex;
    max-height: 100px;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    border-radius: 15px;
    transition: max-height .5s, height .5s, background-color .3s, padding-bottom .3s;
    &:not(:first-child) {
      margin-top: 20px;
    }
    @include _1440 {
      max-height: 80px;
      padding: 20px 25px;
      &:not(:first-child) {
        margin-top: 15px;
      }
      @include _992 {
        max-height: 60px;
        padding: 15px 20px;
        border-radius: 10px;
        &:not(:first-child) {
          margin-top: 10px;
        }
        @include _576 {
          max-height: 40px;
          border-radius: 6px;
          padding: 10px 12px;
          &:not(:first-child) {
            margin-top: 5px;
          }
        }
      }
    }
    &__icon {
      user-select: none;
      height: 100%;
      display: flex;
      align-items: flex-start;
      &, & > svg {
        width: 50px;
        min-width: 50px;
      }
      & > svg {
        height: 50px;
      }
      @include _1440 {
        &, & > svg {
          width: 40px;
          min-width: 40px;
        }
        & > svg {
          height: 40px;
        }
        @include _992 {
          &, & > svg {
            width: 30px;
            min-width: 30px;
          }
          & > svg {
            height: 30px;
          }
          @include _576 {
            &, & > svg {
              width: 20px;
              min-width: 20px;
            }
            & > svg {
              height: 20px;
            }
          }
        }
      }
    }
    &:nth-child(2) &__icon {
      & path {
        fill: url(#advantages-block-svg-gradient);
      }
    }
    & path {
      transition: stroke .3s, fill .3s, transform .3s;
    }
    &.active {
      & path[stroke] {
        stroke: white;
      }
      & path[fill] {
        fill: white;
      }
    }
    &.active {
      max-height: 1000px;
      background: $main-gradient;
      padding-bottom: 50px;
      @include _1440 {
        padding-bottom: 40px;
        @include _1200 {
          padding-bottom: 30px;
          @include _992 {
            padding-bottom: 20px;
            @include _576 {
              padding-bottom: 15px;
            }
          }
        }
      }
    }

    &__content {
      margin-left: 40px;
      @include _1440 {
        margin-left: 30px;
        @include _992 {
          margin-left: 25px;
          @include _768 {
            margin-left: 20px;
            @include _576 {
              margin-left: 16px;
            }
          }
        }
      }
      width: 100%;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      user-select: none;
      height: 50px;
      @include _1440 {
        height: 40px;
        @include _992 {
          height: 30px;
          @include _576 {
            height: 20px;
          }
        }
      }
    }
    &__title {
      @include font(24,32);
      font-weight: 500;
      color: #fff;
      @include _1440 {
        @include font(22,28);
        @include _1200 {
          @include font(20, 26);
          @include _992 {
            @include font(18, 24);
            @include _768 {
              @include font(16,22);
              @include _576 {
                @include font(14,20);
              }
            }
          }
        }
      }
    }
    &__arrow {
      width: 20px;
      height: 20px;
      transform: rotate(180deg);
      transition: transform .3s;
      @include _1440 {
        width: 16px;
        height: 16px;
        @include _992 {
          width: 12px;
          height: 12px;
          @include _576 {
            width: 8px;
            height: 8px;
          }
        }
      }
    }
    &.active &__arrow {
      transform: rotate(0deg);
    }
    &__text {
      margin-top: 20px;
      transition: margin-top .3s;
      user-select: none;
      @include font(20,30);
      color: #fff;
      font-weight: 400;
      @include _1440 {
        @include font(18, 26);
        @include _1200 {
          @include font(16, 22);
          @include _992 {
            @include font(13,18);
            @include _576 {
              @include font(10, 16);
            }
          }
        }
      }
      @include _992 {
        margin-top: 15px;
        @include _576 {
          margin-top: 10px;
        }
      }
    }
    &.active &__text {
      margin-top: 14px;
      @include _992 {
        margin-top: 10px;
        @include _576 {
          margin-top: 5px;
        }
      }
    }
  }
}