.product-card{
    $this: &;
    background: #FFFFFF;
    border: 2px solid #D4D4D4;
    display: block;
    height: auto;
    position: relative;
    padding: calcFluid(8, 20, 320,1920) calcFluid(9, 20,320, 1920) calcFluid(23,40);//110px;

    &__part {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        overflow: hidden; // safari почему-то не воспринимал что-то и фотки вылезали за рамки. Может это из-за флекса или из-за тега object :/
    }

    //@include _1440 {
    //    padding-bottom: 100px;
    //    @include _992 {
    //        padding-bottom: 23px;
    //    }
    //}

    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        justify-content: space-between;
    }

    &__article {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        font-size: calcFluid(10,20,320,1920);
        line-height: calcFluid(10,20,320,1920);
        font-weight: 400;
        color: #929292;
    }

    &__img {
        //Зачем?
        //width: calcFluid(84, 171, 320, 1920);


        margin-top: 17px;
        width: 100%;
        max-width: 270px;

        @include _1440 {

            margin-top: 15px;
            @include _1200 {
                margin-top: 10px;
                @include _768 {
                    margin-top: 7px;
                }
            }
        }

        &.--empty {
            height: 170px;
        }

        &:after {
            content: "";
            padding-top: 100%;
        }
    }
    &__title {
        margin-top: calcFluid(7,34,320, 1920);
        font-size: calcFluid(10,20,320,1920);
        line-height: calcFluid(10,20,320,1920);
        font-weight: 400;
        color: $black;
        max-width: 74.359%;
        text-align: center;
    }
    &__price {
        margin-top: 10px;
        font-size: calcFluid(14,22,320,1920);
        line-height: calcFluid(20,32,320,1920);
        color: $black;
        font-weight: 300;
        text-align: center;
        @include _768 {
            margin-top: 25px;
        }
    }
    &__btn {
        //position: absolute;
        //bottom: 40px;
        //По идее надо было заменить цвет в _buttons.scss, но в ТЗ замена цвета ток в карточках

        background: linear-gradient(90deg, #40BAEB 0%, #4066EB 100%);
        margin-top: 23px;
        min-width: unset !important;
        width: 270px;
        transition: 0.3s all ease-in-out;
        //border: 1px solid $black;
        @include _1440 {
            //bottom: 33px;
        }
        @include _992 {
            display: none;
        }
    }
    &:hover &__btn {
        background: #fff;
        & span {
            background-image: linear-gradient(90deg, #40BAEB 0%, #4066EB 100%);
            background-image: linear-gradient(90deg, #40BAEB 0%, #4066EB 100%);
            color:transparent;
            -webkit-background-clip: text;
            background-clip: text;

        }
    }
}