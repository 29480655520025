.brands-slider {
  position: relative;
  z-index: 1;
  &__container {
    display: flex;
    & .swiper-wrapper {
      display: flex;
    }
  }
  &-slide {
    height: 100%;
    width: 180px;
    display: flex;
    justify-content: center;
    &__img {
      object-fit: contain;
      width: fluidFull(62,180);
      position: relative;
      height: fluidFull(62,180);
      transition: 0.1s all ease-in-out;
      //filter: grayscale(1);
      &:hover {
        transform: translateY(-5px);
        //filter: unset;
      }
    }
  }
}