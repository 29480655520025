.answers-block {
  position: relative;
  padding: 106px 0 102px;
  @include _1440 {
    padding: 80px 0;
    @include _1200 {
      padding: 60px 0;
      @include _992 {
        padding: 50px 0;
        @include _768 {
          padding: 40px 0;
        }
        @include _576 {
          padding: 35px 0 25px;

        }
      }
    }
  }
  &__inner {
    position: relative;
    z-index: 1;
    @include _576 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__title {
    @include font(56,64);
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    max-width: 645px;
    @include _1440 {
      @include font(45, 55);
      max-width: 500px;
      @include _1200 {
        @include font(35, 45);
        max-width: 400px;
        @include _992 {
          @include font(25, 30);
          max-width: 300px;
          @include _576 {
            text-align: center;
            max-width: 184px;
            @include font(16,18);
          }
        }
      }
    }
  }
  &__description {
    margin-top: 30px;
    @include font(18,22);
    font-weight: 400;
    max-width: 356px;
    color: #fff;
    @include _1440 {
      @include font(16,20);
      margin-top: 20px;
      @include _1200 {
        margin-top: 15px;
        @include font(14,18);
        max-width: 300px;
        @include _992 {
          margin-top: 10px;
          @include font(12,14);
          max-width: 250px;
          @include _576 {
            text-align: center;
            max-width: 203px;
            margin-top: 5px;
            @include font(10,12);
          }
        }
      }
    }
  }
  &__btn {
    margin-top: 55px;
    @include _1200 {
      margin-top: 40px;
      @include _992 {
        margin-top: 66px;
        @include _576 {
          max-width: unset;
        }
      }
    }
  }
}