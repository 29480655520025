.recall-form {
  padding: 74px 0 78px;
  @include _1650 {
    padding: 60px 0;
    @include _1440 {
      padding: 50px 0;
      @include _992 {
        padding: 45px 20px 35px;
      }
    }
  }
  background: $white1;
  display: flex;
  justify-content: center;
  align-items: center;
  &__form {
    max-width: 455px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__title {
    text-align: center;
    @include font(32,32);
    @include _1650 {
      @include font(28,28);
      @include _1440 {
        @include font(24,24);
        @include _1200 {
          @include font(22,22);
          @include _992 {
            @include font(18, 20);
            @include _576 {
              @include font(16,16);
            }
          }
        }
      }
    }
    font-weight: 600;
    color: $black;
    text-transform: uppercase;
  }
  &__description {
    margin-top: 20px;
    text-align: center;
    @include font(20,29);
    @include _1650 {
      @include font(18,26);
      @include _1440 {
        @include font(16, 22);
        @include _1200 {
          @include font(14, 20);
          @include _992 {
            margin-top: 15px;
            @include font(12,17);
          }
        }
      }
    }
    color: $gray6;
  }
  &__group {
    width: 100%;
    margin-top: 36px;
    @include _992 {
      margin-top: 31px;
    }
  }
  &__personal {
    margin-top: 22px;
    text-align: center;
    color: $white1-text;
    @include _1200 {
      margin-top: 15px;
    }
    @include _992 {
      color: $black;
    }
    @include _576 {
      max-width: 195px;
    }
    &, & > a {
      @include font(14,17);
      @include _1440 {
        @include font(12, 15);
        @include _992 {
          @include font(10, 14);
        }
      }
      font-weight: 500;
    }
    & > a {
      @include gradient-text($main-gradient);
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__btn {
    margin-top: 27px;
    @include _1440 {
      margin-top: 20px;
      @include _992 {
        margin-top: 15px;
        @include _576 {
          margin-top: 10px;
        }
      }
    }
  }
  &__mobile_reverse {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include _576 {
      flex-direction: column-reverse;
    }
  }
}