@import 'vendors/swiper.min';

@import 'base/app';
@import 'components/category-card';
@import 'components/product-card';
@import 'components/recall_form';
@import 'components/company-info';
@import 'components/advantages-block';
@import 'components/answers-block';
@import 'components/blog-card';
@import 'components/brands-slider';


.index-top {
  background: $black;
  overflow: hidden;
}

.index-slider {
  height: 459px;
  @include _1200 {
    height: 432px;
  }
  width: 100%;
  padding: 0 !important;
  position: relative;

  &__wrapper {
    width: 100%;
    height: 100%;
  }

  &__container {
    height: 100%;
  }

  &__pagination {
    position: absolute;
    bottom: 19px;
    left: 0;
    width: 100%;
    justify-content: center;
    pointer-events: none;

    & .swiper-pagination-bullet {
      pointer-events: all;
      width: 25px;
      height: 12px;
      border: 1px solid rgba(255, 255, 255, 0.85);
      border-radius: 2px;
      opacity: 1;
      background: transparent;
      &:not(:last-child) {
        margin-right: 14px;
        @include _1200 {
          margin-right: 10px;
        }
      }
      &-active {
        background: rgba(255, 255, 255, 0.85);
      }
    }

    @include _1200 {
      bottom: 13px;
      & .swiper-pagination-bullet {
        width: 20px;
        height: 10px;
      }
    }
  }

  &-slide {
    height: 100%;
    position: relative;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      user-select: none;
      pointer-events: none;
      background: rgba(0, 0, 0, .5);
    }

    &__content {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__title {
      @include font(32, 40);
      font-weight: 500;
      color: #fff;
      text-align: center;
      max-width: 332px;
      @include _1200 {
        max-width: 250px;
        @include font(24, 30);
      }
    }

    &__description {
      @include font(18, 22);
      font-weight: 400;
      color: #fff;
      text-align: center;
      max-width: 360px;
      margin-top: 10px;
      @include _1200 {
        margin-top: 5px;
        @include font(14, 17);
      }
    }

    &__btn {
      margin-top: 20px;
      pointer-events: all;
      @include _768 {
        max-width: 222px;
        min-width: 222px;
        height: 40px;
      }
    }
  }
}

.index-categories {
  margin-top: 40px;
  @include _576 {
    margin-top: 15px;
    padding: 0 20px;
  }

  &__container {
    margin: 5px -15px;
    display: flex;
    flex-flow: row wrap;
    @include _1200 {
      margin: 5px -10px;
      @include _576 {
        margin: 5px -5px;
      }
    }
  }

  &-item {
    margin: 15px;
    width: calc(100% / 6 - 30px);
    background: #fff;
    border-radius: 5px;
    position: relative;
    @include _1200 {
      width: calc(100% / 3 - 20px);
      margin: 10px;
    }
    @include _576 {
      margin: 5px;
      width: calc(100% / 2 - 10px);
    }

    &:before {
      content: "";
      display: block;
      padding-top: 100%;
    }

    &__content {
      position: absolute;
      padding: 0 16%;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__img {
      user-select: none;
      width: 100%;

      &:before {
        content: "";
        display: block;
        padding-top: 100%;
      }
    }

    &__wrapper {
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__title {
      text-align: center;
      @include font(24, 24);
      font-weight: 400;
      color: $black !important;
      @include _1650 {
        @include font(22, 22);
      }
      @include _1200 {
        @include font(18, 22);
      }
      @include _576 {
        @include font(14, 14);
      }
    }

    &:hover &__title {
      text-decoration: underline;
    }
  }
}

.index-brands {
  margin-top: 45px;
  border-top: 1px solid $gray5;
  @include _768 {
    margin-top: 20px;
  }
  &__inner {
    overflow: hidden;
  }
  &__wrapper {
    padding: 30px 0;
    @include _1200 {
      padding: 20px 0;
    }
  }
}

.index-products {
  padding: fluidFull(35,70) 0 0;

  &__title {

  }

  &__wrapper {
    margin-top: 20px;
    overflow-x: hidden;
    padding-bottom: 60px;
    @include _992 {
      padding-bottom: 50px;
      @include _576 {
        padding-bottom: 40px;
      }
    }
  }

  &__container {
    display: flex;
    position: relative;
    &:not(.swiper-initialized) {
      justify-content: center;

      & .swiper-wrapper {
        display: flex;
        flex-flow: row wrap;
        margin: -15px;

        & > a {
          width: calc(100% / 4 - 30px);
          margin: 15px;

        }
        @include _1200 {
          margin: -10px;
          & > a {
            margin: 10px;
            width: calc(100% / 4 - 20px);
          }
        }
        @include _992 {
          margin: -5px;
          & > a {
            margin: 5px;
           width: calc(100% / 2 - 10px);
          }
        }
      }
    }
  }

  &__pagination {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(100% + 40px);
    @include _992 {
      top: calc(100% + 30px);
    }
    left: 0;
    z-index: 1;

    & .swiper-pagination-bullet {
      pointer-events: all;
      width: fluidFull(20,25);
      height: fluidFull(10,19);
      opacity: 1;
      border: 1px solid #D4D4D4;
      background: transparent;
      border-radius: 1px;
      transform: skewX(-15deg);
      &-active {
        border: $black;
        background: $black;
      }
      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    @include _1200 {
      bottom: 13px;
      & .swiper-pagination-bullet {
        width: 20px;
        height: 10px;
      }
    }
  }
}

.index-advantages {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 120px;
  @include _1440 {
    padding-bottom: 100px;
    @include _1200 {
      padding-bottom: 80px;
    }
  }
  @include _992 {
    margin-top: 45px;
    padding-bottom: 65px;
  }

  &__title {
    padding: fluidFull(15,65) 0;
    @include _1650 {
        padding: fluidFull(15,65) 20px;
    }
    background: $black;
    width: 100%;
  }

  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include _1200 {
      justify-content: space-between;
      width: 100%;
      padding: 0 15px;
      margin-top: 50px !important;
    }

    &__content {
      margin-left: 40px;
      max-width: 700px;
      @include _1200 {
        margin-top: 0 !important;
        margin-left: 0 !important;
        max-width: scaleFluid(800, 0.8, 600, 1200);
        @include _992 {
          width: 100%;
          max-width: 600px;
        }
      }
    }

    &__wrapper {
      display: flex;
      align-items: center;
      margin-right: 80px;
      @include _1200 {
        margin-top: 0 !important;
        margin-right: 0 !important;
        @include _992 {
          margin-top: 25px !important;
          width: 100%;
          justify-content: center;
        }
      }
    }

    &__img {
      width: scaleFluid(518, .8, 1650, 1920);
      height: unset !important;
      min-width: 300px !important;
      margin-right: 30px;
      position: relative;

      &:after {
        content: "";
        padding-top: 100%;
      }

      @include _992 {
        max-width: 278px;
        margin-right: 0;
        min-width: 278px !important;
      }
    }

    &__title {
      @include font(32, 46);

      @include _1650 {
        @include font(28, 40);
        @include _1440 {
          @include font(26, 30);
          @include _1200 {
            @include font(20, 26);
            @include _576 {
              @include font(16, 20);
            }
          }
        ;
        }
      }
      font-weight: 500;
      color: $black;
      text-transform: uppercase;
      @include _992 {
        width: 100%;
        text-align: center;
        @include _576 {
          text-align: left;
        }
      }
    }

    &__num {
      position: relative;
      z-index: 1;
      @include font(205, 296);
      @include _1650 {
        @include font(180, 260);
        @include _1440 {
          @include font(160, 240);
        }
      }
      font-weight: 900;
      text-transform: uppercase;
      text-align: center;
      @include gradient-text($main-gradient);
      @include _1200 {
        @include font(90, 70);
        position: absolute;
        left: 10px;
        top: 10px;
        margin-top: 0 !important;
        @include _992 {
          left: scaleFluid(300, 0.4, 600, 992);
          top: 5px;
          @include _576 {
            left: 20px;
          }
        }
      }
    }

    &__text {
      margin-top: 40px;
      @include font(20, 32);
      @include _1650 {
        @include font(18, 28);
        @include _1440 {
          margin-top: 30px;
          @include font(16, 20);
          @include _1200 {
            margin-top: 20px;
            @include _992 {
              width: 100%;
              margin-top: 4px;
              @include font(12, 16);
            }
          }
        }
      }
      font-weight: 400;
      color: $black;
    }

    &--reverse {
      flex-direction: row-reverse;

    }

    @include _1200 {
      flex-direction: row-reverse;
      position: relative;
      margin-left: 0 !important;
      margin-right: 0 !important;
      @include _992 {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: unset;
        padding: 0 20px;
      }
    }
    $index-advantages-row: &;

    &.first {
      margin-top: 47px;
      margin-left: 28px;
      @include _992 {
        margin-top: 15px !important;
      }

      #{$index-advantages-row} {
        &__num {
          margin-top: 28px;
        }

        &__wrapper {
          margin-top: 58px;
        }
      }
    }

    &.second {
      margin-top: scaleFluid(182, 0.8, 1650, 1920);;
      margin-right: calcFluid(15, 160, 1600, 1920);
      @include _992 {
        margin-top: 99px !important;
      }

      #{$index-advantages-row} {
        &__img {
          margin-top: -9px;
          width: scaleFluid(616, .8, 1650, 1920);
          height: scaleFluid(364, .8, 1650, 1920);
        }

        &__content {
          margin-left: scaleFluid(60, 0.8, 1650, 1920);;
        }

        &__num {
          margin-top: -28px;

        }
      }
    }

    &.third {
      margin-left: scaleFluid(79, 0.8, 1650, 1920);
      margin-top: scaleFluid(99, 0.8, 1650, 1920);
      @include _992 {
        margin-top: 85px !important;
      }

      #{$index-advantages-row} {

        &__content {
          margin-top: 57px;
          margin-left: 36px;
        }

        &__wrapper {
          margin-right: scaleFluid(150, 0.8, 1650, 1920);

        }

        &__num {
          margin-top: -13px;
          @include _992 {
            top: -8px;
            left: 22px;
          }
        }

        &__img {
          width: scaleFluid(517.95, .8, 1650, 1920);
          height: scaleFluid(494.92, .8, 1650, 1920);
        }
      }
    }
  }
}

.index-blog {
  padding: 50px 0 55px;
  @include _1200 {
    padding: 40px 0 50px;
    @include _768 {
      padding: 20px 0 45px;
      @include _576 {
        padding: 15px 0 45px;
      }
    }
  }
  background: $black;

  &__inner {

  }

  &-top {
    position: relative;

    &__right {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      height: 100%;
      @include _576 {
        height: unset;
        position: unset;
      }
    }
  }

  &__all {
    display: block;
    @include font(16, 16);
    font-weight: 400;
    @include _1440 {
      @include font(14, 16);
    }
    @include _992 {
      @include font(12, 14);
    }
    color: #40BAEB;

    &:hover {
      color: #fff;
    }

    @include _576 {
      margin-top: 15px;
      @include font(10, 14);

    }
  }

  &__container {

    display: flex;
    flex-flow: row wrap;
    margin: -15px;
    margin-top: 5px;
    @include _1200 {
      margin: -10px;
      margin-top: 5px;
      @include _576 {
        margin: -5px;
        margin-top: 0;
      }
    }

    & > a {
      width: calc(100% / 3 - 30px);
      @include _1200 {
        width: calc(100% / 3 - 20px);
        @include _576 {
          width: calc(100% / 2 - 10px);
        }
      }
      @include _576 {
        &:nth-child(3) {
          display: none;
        }
      }
    }
  }
}

.index-sticky {
  overflow: hidden;
  display: none;
  @include _1200 {
    display: flex;
  }
  position: sticky;
  bottom: -1px;
  z-index: 5;
  width: 100%;
  padding: 20px;
  background: $black;
  &__slider {
    width: 100%;
    &:not(.swiper-initialized) .swiper-wrapper {
      display: flex;
      & .index-sticky-slide {
        margin-right: 10px;
      }
    }
  }
  &-slide {
    width: 62px;
    &__img {
      height: 62px;
      width: 100%;
      & > svg {
        width: 100%;
        height: 100%;
      }
    }
    &__title {
      margin-top: 5px;
      font-weight: 400;
      font-size: 8px;
      line-height: 10px;
      text-align: center;
      color: #fff;
    }
  }
}
