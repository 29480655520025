.blog-card {
  padding-bottom: 10px;
  margin: 15px;
  @include _1200 {
    margin: 10px;
    @include _576 {
      margin: 5px;
      padding-bottom: 5px;
    }
  }
  &__img {
    width: 100%;
    height: 272px;
    object-fit: cover;
    border-radius: 5px;
    @include _1200 {
      height: 217px;
      @include _576 {
        height: 94px;
      }
    }
  }
  &__title {
    margin-top: 30px;
    @include font(20,29);
    @include _1440 {
      margin-top: 25px;
      @include font(18, 26);
      @include _1200 {
        margin-top: 20px;
        @include font(16,20);
        @include _992 {
          @include font(14,16);
          margin-top: 10px;
          @include _576 {
            @include font(10,14);
            margin-top: 5px;
          }
        }
      }
    }
    font-weight: 500;
    color: $black;
  }
  &__description {
    margin-top: 10px;
    @include font(18,26);
    font-weight: 400;
    color: $black;
    @include _1200 {
      @include font(14, 20);
      @include _992 {
        @include font(10, 16);
        @include _576 {
          @include font(8, 12);
        }
      }
    }
  }
  &.white &__title {
    color: #fff;
  }
  &.white &__description {
    color: #fff;
  }
  &:hover &__title {
    text-decoration: underline;
  }
}