.company-info {
    padding: 100px 0 114px;
    @include _1650 {
        padding: 60px 0 80px;
        @include _1440 {
            padding: 35px 20px 45px;
        }
    }
    background: $black;
    &__container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 64px;
        @include _1440 {
            flex-direction: column;
            padding: 0;
        }
    }
    &-years {
        user-select: none;
        margin-top: 38px;
        &__row {
            display: flex;
            align-items: flex-end;
        }
        &__num {
            @include font(200,150);
            font-weight: 500;
            @include gradient-text($main-gradient);
            text-transform: uppercase;
            white-space: nowrap;
            & > span {
                @include font(100, 71);
                margin-left: -41px;
            }
            @include _1650 {
                @include font(160, 120);
                & > span {
                    @include font(80, 60);
                }
            }
            @include _1440 {
                & > span {
                    margin-left: -25px;
                }
            }
            @include _992 {
                @include font(126, 95);
                & > span {
                    @include font(63, 40);
                }
            }
        }
        &__text {
            margin-top: 13px;
            margin-left: 11px;
            @include font(28, 40);
            @include _1650 {
                @include font(22,30);
                margin-top: 5px;
                @include _1440 {
                    margin-top: 8px;
                    margin-left: 6px;
                }
                @include _1200 {
                    @include font(20,28);
                }
                @include _768 {
                    @include font(18,26);
                }
            }
            font-weight: 500;
            color: #fff;
            text-transform: uppercase;
        }
        &.desk {
            display: block;
            @include _1440 {
                display: none;
            }
        }
        &.mob {
            display: none;
            @include _1440 {
                display: block;
            }
        }
        @include _1440 {
            margin-top: 42px;

        }
    }
    &__right {
        margin-top: -6px;
        margin-left: scaleFluid(140, 0.8, 1650, 1920);
        @include _1440 {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-left: 0;
        }
    }
    &__text {

    }
    &__title {
        @include font(48,69);
        @include _1650 {
            @include font(40, 56);
            @include _1440 {
                @include font(36, 46);
                @include _1200 {
                    @include font(32, 38);
                    @include _992 {
                        @include font(28, 36);
                        @include _576 {
                            @include font(24, 34)
                        }
                    }
                }
            }
        }
        font-weight: 500;
        @include gradient-text($main-gradient);
        text-transform: uppercase;
        @include _1440 {
            text-align: center;
        }
    }
    &__description {
        margin-top: 5px;
        @include font(18,22);
        @include _1440 {
            @include font(16, 20);
            @include _768 {
                @include font(14,17);
            }
        }
        font-weight: 500;
        color: #fff;
        @include _1440 {
            text-align: center;
        }
    }
    &__indicators {
        margin-top: 3px;
        display: flex;
        @include _1440 {
            flex-flow: row wrap;
            justify-content: space-around;
            @include _576 {
                margin-top: 8px;
            }
        }
    }
    &-indicator {
        margin: 25px 80px;

        &:first-child {
            margin: 25px 80px 25px 0;
        }
        &:last-child {
            margin: 25px 0 25px 80px;
        }
        @include _1440 {
            display: flex;
            flex-direction: column;
            align-items: center;
            &:first-child, &:last-child, & {
                margin: 16.5px 30px;
            }
            @include _576 {
                margin: 12.5px 0;
                &:last-child {
                    margin: 12.5px 0 0;
                }
            }
        }
        &__value {
            @include font(64,92);
            font-weight: 500;
            text-transform: uppercase;
            color: #fff;
        }
        &__title {
            margin-top: 5px;
            @include font(22,32);
            @include _1200 {
                @include font(20,28);
                @include _768 {
                    @include font(18,26);
                    margin-top: -6px;
                }
            }
            text-transform: uppercase;
            font-weight: 500;
            color: #fff;
        }
    }
}